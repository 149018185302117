@charset "utf-8";

@use './colors.scss' as colors;

$primary: colors.$refitit-dark-blue !default;
$warning: colors.$refitit-orange !default;
$info: colors.$refitit-blue !default;
$link: colors.$refitit-orange !default;

/**
 * Import Bulma.
 */

// First import resources with customizations

@use 'bulma/sass/utilities/initial-variables.scss' as iv with (
  $family-sans-serif: "'Nunito', sans-serif",
  $grey-dark: colors.$brown
);

@use 'bulma/sass/utilities/derived-variables.scss' as dv with (
  $primary: $primary,
  $warning: $warning,
  $info: $info,
  $link: $link
);

@use 'bulma/sass/utilities/css-variables' as cv;

@use 'bulma/sass/utilities/controls.scss' with (
  $control-border-width: 0.125em
);

@use 'bulma/sass/base/generic.scss' with (
  // FIXME the fixed color that overrides the body background
  // color is not theme compliant (it is constant among light/dark
  // theme). To become compliant with light/dark theme strategy,
  // we should probably rely on a CSS variable accessed through
  // cv.getVar('custom-body-background').
  $body-background-color: colors.$beige-lighter
);

@use 'bulma/sass/elements/box.scss' with (
  $box-padding: 0.5rem
);

@use 'bulma/sass/components/modal.scss' with (
  // Instead of 2rem by default
  $modal-card-head-padding: 1.3rem,
  // Instead of 2rem by default
  $modal-card-body-padding: 1rem
);

@use 'bulma/sass/components/tabs.scss' with (
  $tabs-link-active-border-bottom-color: dv.$primary,
  $tabs-link-active-color: dv.$primary,
  $tabs-toggle-link-active-background-color: dv.$primary,
  $tabs-toggle-link-active-border-color: dv.$primary
);

@use 'bulma/sass/layout/footer.scss' with (
  //$footer-padding: 3rem 1.5rem 6rem !default
  $footer-padding: 0.5rem 0.5rem 0.5rem
);

// At the end import all what has not yet been yet imported through customizations
@use 'bulma/bulma.scss';

/** End of Bulma import */

// Bulma switch extension (rewritten due to bulma 1.0 migration)
// @use 'bulma-switch/src/sass/index.sass';
@use './bulma-switch/index.scss' as bs;

// Bulma calendar extension (scss rewritten due to bulma 1.0 migration, but still using the module JS)
// @use 'bulma-calendar/src/sass/index.sass';
@use './bulma-calendar/index.scss' as bc;

// Bulma quickview extension
@use './bulma-quickview/index.scss';

// Custom extensions
@use './spacing.scss';
@use './custom.scss';

// Font awesome
@use '@fortawesome/fontawesome-free/scss/brands' with (
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/'
);
@use '@fortawesome/fontawesome-free/scss/fontawesome' with (
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/'
);
@use '@fortawesome/fontawesome-free/scss/regular' with (
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/'
);
@use '@fortawesome/fontawesome-free/scss/solid' with (
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/'
);

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
