@use 'bulma/sass/utilities/initial-variables.scss' as iv;
@use 'bulma/sass/utilities/derived-variables.scss' as dv;
@use 'bulma/sass/utilities/css-variables' as cv;
@use 'bulma/sass/components/navbar' as navbar;

// Style copied from https://github.com/Wikiki/bulma-quickview/blob/master/src/sass/index.sass

$quickview-z: 35 !default;
$quickview-header-height: 3.25em !default;
$quickview-footer-height: 4rem !default;
$quickview-shadow: 5px 0px 13px 3px rgba(0, 0, 0, 0.1) !default;
$quickview-divider: 1px solid iv.$grey-lighter !default;

$quickview-margin-top: navbar.$navbar-height;

.quickview {
  display: flex;
  flex-direction: column;
  background-color: iv.$white;
  max-width: calc(100% - 50px);
  &.is-marginless {
    max-width: 100%;
  }
  position: fixed;
  top: $quickview-margin-top;
  bottom: 0;
  z-index: $quickview-z;
  transform: translateZ(0);
  transition: 0.3s ease;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  @media (max-width: 768px) {
    width: 100%;
    right: -100%;
  }
  @media (min-width: 769px) {
    width: 50%;
    right: -50%;
  }
  @media (min-width: 1088px) {
    width: 35%;
    right: -35%;
  }
  @media (min-width: 1280px) {
    width: 30%;
    right: -30%;
  }
  @media (min-width: 1472px) {
    width: 25%;
    right: -25%;
  }
  &.is-left {
    @media (max-width: 768px) {
      left: -100%;
    }
    @media (min-width: 769px) {
      left: -50%;
    }
    @media (min-width: 1088px) {
      left: -35%;
    }
    @media (min-width: 1280px) {
      left: -30%;
    }
    @media (min-width: 1472px) {
      left: -25%;
    }
  }
  &.is-active {
    right: 0;
    box-shadow: $quickview-shadow;
    &.is-left {
      left: 0;
    }
  }
}

.quickview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  min-height: $quickview-header-height !important;
  border-bottom: $quickview-divider;
  @each $name, $pair in dv.$colors {
    &.is-#{$name} {
      background-color: cv.getVar($name);
      .title {
        color: cv.getVar($name, '', '-invert');
      }
    }
  }
  .title {
    font-size: iv.$size-6;
    font-weight: iv.$weight-light;
    margin-bottom: 0;
    img {
      max-height: 2em;
    }
  }
}

.quickview-body {
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  overflow-y: auto;
}

.quickview-footer {
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0rem 1rem;
  min-height: $quickview-footer-height;
  background-color: iv.$white-ter;
  border-top: $quickview-divider;
  > * {
    margin: 0 0.4rem;
  }
}
