// RefitIT colors
$refitit-dark-blue: #46599f;
$refitit-blue: #849bf1;
$refitit-dark-yellow: #b88f20;
$refitit-yellow: #ffd900;
$refitit-orange: #f69640;
$refitit-dark-purple: #9484f1;
$refitit-purple: #afa4f8;
$refitit-green-light: #b0f2b6;
$refitit-orange-light: #f2d197;
$refitit-red-light: #ff8484;

// Others
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
