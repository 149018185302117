@use 'bulma/sass/utilities/initial-variables' as iv;
@use 'bulma/sass/utilities/derived-variables' as dv;
@use 'bulma/sass/form/shared' as shared;
@use './colors.scss' as colors;
@use 'bulma/sass/base/generic.scss' as generic;
@use 'bulma/sass/utilities/css-variables' as cv;
@use 'bulma/sass/utilities/mixins' as mx;

.bulletIcon {
  margin-left: 3px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  min-width: 1.25rem;
  min-height: 1.25rem;
  line-height: 1.25rem;
  padding: 2px;
}

.pulsating {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
  z-index: 30;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blink {
  outline: 5px solid;
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  70% {
    outline: 1px solid;
  }
}

.bulma-box-shadow {
  box-shadow:
    0 2px 3px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.dropdown.is-fullwidth {
  display: flex;

  .dropdown-trigger {
    width: 100%;
  }
}

.tabs {
  ul {
    li {
      &.is-disabled {
        a {
          color: lightgrey;
          cursor: default;
        }
      }
    }
  }
}

.vtabs-wrapper {
  display: flex;
}

.vtabs {
  min-width: 10%;
  margin-right: 30px;

  ul {
    display: block;
    text-align: left;
    border-bottom-color: transparent !important;

    li {
      display: block;

      &.is-active {
        a {
          border-right-color: dv.$link;
          color: dv.$link;
        }
      }

      a {
        display: block;
        color: dv.$text;
        border-bottom-color: transparent !important;
        border-right: 2px solid #dbdbdb;
        padding: 0.5em 1em;
      }
    }
  }
}

.content-wrap {
  .vtab-content {
    padding-top: 12px;
  }
}

ul.hide-bullets,
ol.hide-bullets {
  list-style: none;
  margin-left: 0em;
}

.keyboardContainer {
  display: flex;
  background-color: rgb(236, 236, 236);
  justify-content: center;
  margin: 0 auto;
  border-radius: 5px;
  position: fixed;
  z-index: 40000000;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.simple-keyboard-main.simple-keyboard {
  width: 75%;
  min-width: 640px;
  align-self: flex-start;
  background: none;
}

.simple-keyboard .hg-button.selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: white;
}

.simple-keyboard-control.simple-keyboard .hg-row:first-child {
  margin-bottom: 10px;
}

.simple-keyboard.hg-theme-default .hg-button.hg-selectedButton {
  background: rgba(5, 25, 70, 0.53);
  color: white;
}

.numPad {
  display: flex;
  flex-grow: 1;
}

.simple-keyboard-numpad.simple-keyboard {
  background: none;
  min-width: 120px;
}

.modal {
  &.is-fullscreen {
    .modal-content,
    .modal-card {
      width: 99%;
      height: 98%;
      max-height: 100vh;
      margin: 0;
      // background-color: #fff;
    }
  }
}

// max-desktop refers to https://bulma.io/documentation/layout/container/#overview
.modal {
  &.is-max-desktop {
    .modal-content,
    .modal-card {
      width: 960px;
    }
  }
}

// max-widescreen refers to https://bulma.io/documentation/layout/container/#overview
.modal {
  &.is-max-widescreen {
    .modal-content,
    .modal-card {
      width: 1152px;
    }
  }
}

.radio-list {
  .radio {
    display: block;

    & + .radio {
      margin-left: 0;
      margin-top: 0.5em;
    }
  }
}

%button-is-smaller,
.button.is-smaller {
  @extend .is-small;
  height: 1.5em;
  padding-right: 0.65em;
  padding-left: 0.65em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

%sharp-button-is-smaller,
#button.is-smaller {
  @extend %button-is-smaller;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.buttons.are-smaller {
  margin-right: 0rem;
  gap: 5px;
}

.buttons.are-smaller .button {
  @extend %button-is-smaller;
}

.buttons.are-smaller #button {
  @extend %sharp-button-is-smaller;
}

.buttons.is-vertical {
  flex-direction: column;
}

.buttons.is-vertical .button:not(:last-child) {
  margin-right: 0;
}

.table.is-narrow td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.table.is-narrow tr {
  line-height: 1.5;
}

.no-padding {
  padding: 0.2rem;
}

.is-transparent {
  background-color: generic.$body-background-color;
}

.is-green-light {
  background-color: #b0f2b6;
}

.has-background-beige-light {
  background-color: colors.$beige-light !important;
}

// Workaround because a field with addon cannot have an help text
// https://github.com/jgthms/bulma/issues/640#issuecomment-415231212
.field .field {
  margin-bottom: 0;
}

@mixin mimicInput {
  // Copied from bulma/sass/utilities/controls.scss
  // to get the same margins in the component as standard
  // bulma inputs
  border-style: solid;
  border-width: cv.getVar('control-border-width');
  border-radius: cv.getVar('control-radius');

  padding-bottom: cv.getVar('control-padding-vertical');
  padding-left: cv.getVar('control-padding-horizontal');
  padding-right: cv.getVar('control-padding-horizontal');
  padding-top: cv.getVar('control-padding-vertical');

  // Copied from bulma/sass/form/shared.scss > input
  background-color: hsl(
    #{cv.getVar('input-h')},
    #{cv.getVar('input-s')},
    calc(#{cv.getVar('input-background-l')} + #{cv.getVar('input-background-l-delta')})
  );
  border-color: hsl(
    cv.getVar('input-h'),
    cv.getVar('input-s'),
    calc(#{cv.getVar('input-border-l')} + #{cv.getVar('input-border-l-delta')})
  );
  border-radius: cv.getVar('input-radius');
  color: hsl(#{cv.getVar('input-h')}, #{cv.getVar('input-s')}, #{cv.getVar('input-color-l')});

  @include mx.placeholder {
    color: cv.getVar('input-placeholder-color');
  }

  // Copied from bulma/sass/form/shared.scss > input
  // to get the same bahaviour in the component as standard
  // bulma inputs when the input gabs the focus
  &:focus,
  &:focus-within,
  &.#{iv.$class-prefix}is-focused {
    border-color: hsl(
      cv.getVar('input-focus-h'),
      cv.getVar('input-focus-s'),
      cv.getVar('input-focus-l')
    );
    box-shadow: cv.getVar('input-focus-shadow-size')
      hsla(
        cv.getVar('input-focus-h'),
        cv.getVar('input-focus-s'),
        cv.getVar('input-focus-l'),
        cv.getVar('input-focus-shadow-alpha')
      );
  }
}

.mimicInput {
  @include mimicInput;
}

.mimicInput .placeholder {
  color: shared.$input-placeholder-color;
}

h2 {
  margin-top: 1.5625em;
}

// Equivalent to CSS property "text-decoration: line-through" but on the whole tr element
tr.strikeout td {
  position: relative;
}

tr.strikeout td:before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 1px solid #5a515185;
  width: 100%;
}

@media print {
  .no-printing {
    display: none !important;
  }

  .printable-scroll {
    height: 100%;
    overflow: visible !important;
  }

  body,
  html,
  #printable {
    width: 100%;
    margin: 0;
    float: none;
  }

  @page {
    size: auto;
    margin-top: 1cm;
    margin-bottom: 1cm;
  }

  .box {
    border-radius: 0em;
    box-shadow: none;
    padding-top: 0em;
  }

  .navbar {
    display: none;
  }

  td {
    padding: none !important;
    margin: none !important;
  }

  th {
    padding: none !important;
    margin: none !important;
  }

  body {
    font:
      10pt Georgia,
      'Times New Roman',
      Times,
      serif !important;
    font-family: 'Times New Roman', serif;
    line-height: 1;
    background: #fff !important;
    color: #000;
  }

  h1 {
    font-size: 24pt;
    font-weight: 600;
  }

  h2 {
    font-size: 20pt;
    font-weight: 600;
    margin-top: 1em;
  }

  /* Defining all page breaks */
  a {
    page-break-inside: avoid;
  }
  blockquote {
    page-break-inside: avoid;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }
  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
  pre {
    page-break-inside: avoid;
  }
  thead {
    page-break-after: avoid;
  }
  ul,
  ol,
  dl {
    page-break-before: avoid;
  }
}

.nobackground-fixed-middle-right {
  background: none;
  position: fixed;
  right: 20px;
  border: none;
}

.nobackground-fixed-middle-left {
  background: none;
  position: fixed;
  left: 20px;
  border: none;
}

.action-needed {
  background-color: #f2d197;
}

.action-needed-lighter {
  background-color: #fce7c4;
}

.is-workshop-post-vertical-narrow {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.is-empty-workshop-post {
  background-color: #dcdbd8;
}

.vertical-grid-column-with-generic-spacing {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  padding: 10px 10px 10px 10px;
}

.vertical-tabs {
  min-width: 46.5px;
  float: left;
  border-right: 1px solid #ddd;
}
.vertical-tabs ul {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.no-border-tabs {
  .tabs {
    border: none;
    padding-right: 3px;
    ul {
      border: none;
      li {
        flex-grow: 1;
      }
    }
  }
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: upright;
  font-size: small;
  border: none;
  position: relative;
  flex-grow: 1;
}

.no-border-cells {
  td {
    border-color: white !important;
  }
}

.is-full-height {
  height: 100%;
}

$gradient-kanban-age-colors: #dbdbdb, #ded3ca, #e1ccb9, #e4c4a8, #e7bc97, #eab585, #edad74, #f0a563,
  #f39e52, #f69641;

@for $i from 0 through 9 {
  td.has-background-kanban-age-#{$i},
  .has-background-kanban-age-#{$i} {
    background-color: nth($gradient-kanban-age-colors, $i + 1);
  }
}

.spaced-icon {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.clickable-icon-default-color {
  color: iv.$black;
}

.clickable-icon-default-disabled-color {
  color: iv.$grey-lighter;
}

.has-text-orange-light {
  color: colors.$refitit-orange-light;
}

td.has-background-orange-light,
.has-background-orange-light {
  background-color: colors.$refitit-orange-light;
}

.has-text-red-light {
  color: colors.$refitit-red-light;
}

td.has-background-red-light,
.has-background-red-light {
  background-color: colors.$refitit-red-light;
}

.has-text-green-light {
  color: colors.$refitit-green-light;
}

td.has-background-green-light,
.has-background-green-light {
  background-color: colors.$refitit-green-light;
}

.has-text-yellow {
  color: colors.$refitit-yellow;
}

td.has-background-yellow,
.has-background-yellow {
  background-color: colors.$refitit-yellow;
}

.is-active-box {
  box-shadow: inset 0px 0px 0px 5px dv.$primary;
  box-sizing: border-box;
}

.has-primary-color-border {
  border: solid dv.$primary;
}

.is-display-flex {
  display: flex;
}

.is-align-items-center {
  align-items: center;
}

.has-text-anomaly {
  color: #690808;
}

.has-background-anomaly {
  background-color: #690808;
  color: iv.$white;
}

.tab-label {
  display: flex;
  align-items: center;
}

.horizontal-tab-label {
  height: 1rem;
}

div.disable-bulma-child-margin-bottom {
  margin-bottom: 0;
  &:not(:last-child) {
    margin-bottom: 0;
  }
}
