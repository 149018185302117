$theme: 'refititBlue';
.mlm-navbar {
  .navbar-menu {
    width: 100%;
    .navbar-items-container {
      width: 100%;
      max-width: 800px;
      .navbar-item-container {
        width: 100%;
      }
    }
  }
}
