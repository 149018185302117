$theme: 'refititBlue';
.picture-selector {
  .target {
    user-select: none;
    display: block;
  }

  .clickable-image {
    cursor: crosshair;
  }

  .button-icon {
    width: 50px;
    &.selected {
      border-width: 2px;
      border-color: hsl(
        var(--bulma-primary-h),
        var(--bulma-primary-s),
        var(--bulma-primary-l)
      ) !important;
    }
  }

  .bullet-marker {
    position: absolute;
    transform: translate(-50%, -50%);
    user-select: none;
  }
}
