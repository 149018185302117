$theme: 'refititBlue';
$theme: 'refititDev' !default;

@use './compute-colors.scss' as colors with (
  $theme: $theme
);

// Import uitoolkit sass
@use '@stimcar/libs-uitoolkit/dist/react/bulma/sass/styles.scss' as uitoolkit with (
  $primary: colors.$primary,
  $info: colors.$info,
  $link: colors.$link
);

// At the end import all what has not yet been yet imported through customizations
@use 'bulma/bulma.scss';

.column {
  padding: 0.5rem;
}

.button.is-narrow {
  margin: 1px;
  border-radius: 0.5rem;
  border: 0.1rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 1.5rem;
}

.button.is-transparent {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
